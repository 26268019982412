import React from 'react'

class BienvenidoHiru extends React.Component {

    render() {
        return (
            <div>
                <h3>Bienvenido al servidor de Licencias de HIRU.</h3>             
            </div>
        )
    }
}

export default BienvenidoHiru;